import React from "react";
import { graphql, Link } from "gatsby";
import parse from "html-react-parser";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useForm, ValidationError } from "@formspree/react";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import Layout from "../components/layout";
import moment from "moment";
import { GatsbyImage } from "gatsby-plugin-image";
import { Helmet } from "react-helmet";
import EventForm from "../components/event-form";

const EventTemplate = ({ data: { event, site } }) => {
	const [state, handleSubmit] = useForm("xzboblvd");

	const siteUrl = site.siteMetadata.siteUrl;
	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "Events",
				item: {
					url: `${siteUrl}/events`,
					id: `${siteUrl}/events`,
				},
			},
			{
				"@type": "ListItem",
				position: "3",
				name: `${event.title}`,
				item: {
					url: `${siteUrl}/events/${event.slug}`,
					id: `${siteUrl}/events/${event.slug}`,
				},
			},
		],
	};
	return (
		<Layout>
			<Helmet>
				<script
					src="https://www.google.com/recaptcha/api.js"
					async
					defer
				></script>{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			<GatsbySeo
				title={`${event.title} | Friends of Eastbourne Hospital`}
				description={parse(event.eventFields.description)}
				openGraph={{
					url: `${siteUrl}/events/${event.slug}`,
					title: `${event.title} | Friends of Eastbourne Hospital`,
					description: parse(event.eventFields.description),
					type: "article",
					images: [
						{
							url: `${event.eventFields.featuredImage.localFile.publicURL}`,
							width: `${event.eventFields.featuredImage.localFile.childImageSharp.original.width}`,
							height: `${event.eventFields.featuredImage.localFile.childImageSharp.original.height}`,
							alt: `${event.eventFields.featuredImage.altText}`,
						},
					],
				}}
			/>
			<section className="bg-light-blue py-5 py-lg-8">
				<Container>
					<Row className="align-items-center text-center text-lg-start bg-light-blue gx-0">
						<Col lg={6}>
							{" "}
							<GatsbyImage
								class="w-100  "
								image={
									event.eventFields.featuredImage?.localFile.childImageSharp
										.gatsbyImageData
								}
								alt={event.eventFields.featuredImage?.localFile.altText}
							/>
						</Col>
						<Col
							className=" px-md-7 px-lg-4 px-xl-7 py-5 px-4"
							xs={{ span: 12, order: "first" }}
							lg={{ span: 6, order: "last" }}
						>
							<p className="fs-5 inter-regular text-main-red">
								{moment(
									new Date(event.eventFields.dateAndTime.replace(" ", "T"))
								).format("dddd DD MMMM")}
								{event.eventFields.extraDate === true && (
									<span>
										{" "}
										-{" "}
										{moment(
											new Date(
												event.eventFields.extraDateAndTime.replace(" ", "T")
											)
										).format("dddd DD MMMM")}
									</span>
								)}
							</p>
							<h1 className="display-4  text-primary ">
								{event.eventFields.heading}
							</h1>
							{event.eventFields.subHeading && (
								<p className="text-text-blue fs-5 pt-4 inter-regular">
									{event.eventFields.subHeading}
								</p>
							)}
							{event.eventFields.registerInterest === true && (
								<Button
									variant="primary"
									className=" py-3 mt-5 w-100 w-md-auto text-white px-4 "
									as={Link}
									to="#form"
								>
									Register Interest
								</Button>
							)}
						</Col>
					</Row>
				</Container>
			</section>
			<section>
				<Container className=" py-md-7 py-5">
					<Row className="">
						<Col lg={6}>
							<div className="event-content px-4 px-md-0">
								{parse(event.eventFields.description)}
							</div>
						</Col>
						<Col className="ps-lg-7 text-center text-lg-start" lg={6}>
							<h2 className="text-primary pb-3">Date and time</h2>
							<p className="pb-1 mb-1">
								{" "}
								{moment(
									new Date(event.eventFields.dateAndTime.replace(" ", "T"))
								).format("dddd DD MMMM")}
								{event.eventFields.extraDate === true && (
									<span>
										{" "}
										-{" "}
										{moment(
											new Date(
												event.eventFields.extraDateAndTime.replace(" ", "T")
											)
										).format("dddd DD MMMM")}
									</span>
								)}
							</p>
							<p className="pb-4">
								{" "}
								{moment(
									new Date(event.eventFields.dateAndTime.replace(" ", "T"))
								).format("HH:mm")}{" "}
								{event.eventFields.endTime && (
									<span>- {event.eventFields.endTime}</span>
								)}
							</p>
							<h2 className="text-primary pb-3">Location</h2>
							<p className="pb-4">{event.eventFields.location}</p>
							{event.eventFields.mapLink && (
								<a
									className="red-link"
									href={event.eventFields.mapLink}
									target="_blank"
									rel="noreferrer"
								>
									{" "}
									View Map
								</a>
							)}
						</Col>
					</Row>
				</Container>
			</section>
			{event.eventFields.registerInterest === true && (
				<section>
					<Container id="form" className=" pb-8">
						<Row>
							<Col lg={6}>
								<EventForm event={event} />
							</Col>
						</Row>
					</Container>
				</section>
			)}
		</Layout>
	);
};

export default EventTemplate;

export const pageQuery = graphql`
	query EventById($id: String!) {
		event: wpEvent(id: { eq: $id }) {
			id
			slug
			title
			eventFields {
				registerInterest
				dateAndTime
				extraDate
				extraDateAndTime
				description
				location
				heading
				mapLink
				subHeading
				endTime
				featuredImage {
					altText
					localFile {
						publicURL
						childImageSharp {
							original {
								height
								width
							}
							gatsbyImageData(
								quality: 50
								placeholder: BLURRED
								layout: FULL_WIDTH
							)
						}
					}
				}
			}
		}

		site {
			siteMetadata {
				siteUrl
			}
		}
	}
`;
