import React, { useEffect, useRef } from "react";
import { useForm, ValidationError } from "@formspree/react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import moment from "moment";

function EventForm({ event }) {
	const [state, handleSubmit] = useForm("moqrkdnw");
	const defaultFormValue = event.eventFields.extraDate
		? `${event.eventFields.heading} from ${moment(
				new Date(event.eventFields.dateAndTime.replace(" ", "T"))
		  ).format("DD MMMM")} - ${moment(
				new Date(event.eventFields.extraDateAndTime.replace(" ", "T"))
		  ).format("DD MMMM")}`
		: `${event.eventFields.heading} on ${moment(
				new Date(event.eventFields.dateAndTime.replace(" ", "T"))
		  ).format("DD MMMM")}`;

	if (state.succeeded) {
		return (
			<div>
				{" "}
				<Form className="w-100" onSubmit={handleSubmit}>
					<Form.Group className="mb-3" controlId="name">
						<Form.Label className="fs-5">Name</Form.Label>
						<Form.Control
							required
							className="py-4 fs-5 text-secondary"
							name="name"
							type="text"
						/>
					</Form.Group>
					<ValidationError prefix="Name" field="name" errors={state.errors} />
					<Form.Group className="mb-3" controlId="email">
						<Form.Label className="fs-5">Email</Form.Label>
						<Form.Control
							className="py-4 fs-5 text-secondary"
							name="_replyto"
							type="email"
						/>
					</Form.Group>
					<ValidationError prefix="Email" field="email" errors={state.errors} />
					<Form.Group className="mb-3" controlId="telephone">
						<Form.Label className="fs-5">Telephone Number</Form.Label>
						<Form.Control
							className="py-4 fs-5 text-secondary"
							type="tel"
							name="telephone"
						/>
					</Form.Group>

					<ValidationError
						prefix="Telephone"
						field="telephone"
						errors={state.errors}
					/>
					<Form.Group className="mb-3" controlId="event">
						<Form.Label className="fs-5">Event</Form.Label>
						<Form.Control
							className="py-4 fs-5 text-secondary"
							name="event"
							type="text"
							readOnly
							value={defaultFormValue}
						/>
					</Form.Group>

					<ValidationError prefix="Event" field="event" errors={state.errors} />
					<Form.Group className="mb-3" controlId="comments">
						<Form.Label className="fs-5">Comments</Form.Label>
						<Form.Control
							className="py-4 fs-5 text-secondary"
							name="comments"
							as="textarea"
							rows={3}
						/>
					</Form.Group>

					<ValidationError
						prefix="Comments"
						field="comments"
						errors={state.errors}
					/>
					{/* 
								<div
									class="g-recaptcha"
									data-sitekey="6LcGzDIfAAAAADFRloAf1kbMqoVVlJerWOdiW2te"
								></div> */}
					<div className="text-start">
						<Button
							variant="primary"
							className=" py-3 mt-5 w-100 w-md-auto text-white px-4 "
							type="submit"
							id="contact-send-btn"
							disabled={state.submitting}
						>
							Register Interest
						</Button>
					</div>
				</Form>
				<div className="d-none">{(window.location = "/thank-you")}</div>
			</div>
		);
	}
	return (
		<Form className="w-100" onSubmit={handleSubmit}>
			<Form.Group className="mb-3" controlId="name">
				<Form.Label className="fs-5">Name</Form.Label>
				<Form.Control
					required
					className="py-4 fs-5 text-secondary"
					name="name"
					type="text"
				/>
			</Form.Group>
			<ValidationError prefix="Name" field="name" errors={state.errors} />
			<Form.Group className="mb-3" controlId="email">
				<Form.Label className="fs-5">Email</Form.Label>
				<Form.Control
					className="py-4 fs-5 text-secondary"
					name="_replyto"
					type="email"
				/>
			</Form.Group>
			<ValidationError prefix="Email" field="email" errors={state.errors} />
			<Form.Group className="mb-3" controlId="telephone">
				<Form.Label className="fs-5">Telephone Number</Form.Label>
				<Form.Control
					className="py-4 fs-5 text-secondary"
					type="tel"
					name="telephone"
				/>
			</Form.Group>

			<ValidationError
				prefix="Telephone"
				field="telephone"
				errors={state.errors}
			/>
			<Form.Group className="mb-3" controlId="event">
				<Form.Label className="fs-5">Event</Form.Label>
				<Form.Control
					className="py-4 fs-5 text-secondary"
					name="event"
					type="text"
					readOnly
					value={defaultFormValue}
				/>
			</Form.Group>

			<ValidationError prefix="Event" field="event" errors={state.errors} />
			<Form.Group className="mb-3" controlId="comments">
				<Form.Label className="fs-5">Comments</Form.Label>
				<Form.Control
					className="py-4 fs-5 text-secondary"
					name="comments"
					as="textarea"
					rows={3}
				/>
			</Form.Group>

			<ValidationError
				prefix="Comments"
				field="comments"
				errors={state.errors}
			/>

			{/* <div
				class="g-recaptcha"
				data-sitekey="6LfMxmofAAAAAJrkK5OeFgJJ53LXPVQJPoNmyxYF"
			></div> */}
			<div className="text-start">
				<Button
					variant="primary"
					className=" py-3 mt-5 w-100 w-md-auto text-white px-4 "
					type="submit"
					id="contact-send-btn"
					disabled={state.submitting}
				>
					Register Interest
				</Button>
			</div>
		</Form>
	);
}
export default EventForm;
